import { defineNuxtPlugin } from '#imports';
import { googleMetricsId, googleMetricsId2 } from '@/analytic/google-metrics';
import { useAnalytics } from '@/composables/useAnalytics';

const { initGtmAnalytic } = useAnalytics();

export default defineNuxtPlugin(() => {
  initGtmAnalytic(googleMetricsId);
  initGtmAnalytic(googleMetricsId2);
});
