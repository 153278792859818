import { dLayer, ymAnalytic } from '@/utils/helpers';
import { yandexMetricsId } from '@/analytic/yandex/yandex-metrics';

export const commonAnalytic = {
  /**
   * @description Каждый раз когда пользователь логинится на сайте, необходимо отправлять событие с идентификатором пользователя
   * @param {Boolean} id - Уникальный ID пользователя
   */
  authentication(id = false) {
    dLayer({
      event: 'authentication',
      UserID: id,
    });
  },

  /**
   * @description После того как пользователь кликает на одну из кнопок, должно отправляться событие
   * @param {Boolean} adult - название вопроса
   */
  ageVerification(adult = false) {
    const label = adult ? 'Мне есть 18 лет' : 'Мне нет 18 лет';
    dLayer({
      event: 'interaction',
      site_section: 'age_verification',
      action: 'click',
      label: label,
    });
  },

  /**
   * @description После того как пользователь кликает на один из элементов в меню (если меню выпадающее, также событие должно отправляться при клике на появляющиеся элементы), должно отправляться событие
   * @param {string} label - название выбранного элемента меню
   */
  clickMenu(label = '') {
    dLayer({
      event: 'interaction',
      site_section: 'menu',
      action: 'click',
      eventContext: 'main',
      label: label,
    });
  },

  /**
   * @description После того как пользователь кликает на один из элементов футера, должно отправляться событие
   * @param {String} label - название выбранного элемента подменю
   */
  footerInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'footer',
      action: 'click',
      label,
    });
  },

  /**
   * @description Когда пользователь кликает по меню, должно отправляться событие
   * @param {String} label - название выбранного пункта меню
   */
  productsMenuInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'menu',
      action: 'click',
      label,
      eventContext: 'product',
    });
  },

  /**
   * @description Когда пользователь кликает на продукт, должно отправляться событие
   * @param {String} label - название продукта
   */
  productsPageProductInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'page',
      action: 'click',
      label,
    });
  },

  /**
   * @description Когда пользователь выбирает продукт, должно отправляться событие
   * @param {String} label - название продукта
   */
  productPageSideMenuProductInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'menu',
      action: 'click',
      label,
      eventContext: 'SKU',
    });
  },

  /**
   * @description Когда пользователь кликает по меню, должно отправляться событие
   * @param {String} label - название выбранного пункта меню
   */
  productPageSliderMenuInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'menu',
      action: 'click',
      label,
      eventContext: 'slider',
    });
  },

  /**
   * @description Когда пользователь кликает по меню, должно отправляться событие
   * @param {String} label - название выбранного продукта
   */
  productPageSliderProductInteraction(label) {
    dLayer({
      event: 'interaction',
      site_section: 'page',
      action: 'click',
      label,
      eventContext: 'slider',
    });
  },

  /**
   * @description При появлении ошибки 404, должно отправляться событие
   */
  error404() {
    dLayer({
      event: 'interaction',
      site_section: 'error_404',
      action: 'error_404',
    });
  },

  /**
   * @description После того как пользователь отправляет форму обратной связи/форму с отзывом о продукте
   * @param {string} type - тип отправляемой формы
   * @param {feedback | review} type - feedback: обратная связь, review: отзыв
   */
  sendFeedbackOrReview(type) {
    const label = {
      feedback: 'feedback',
      review: 'review',
    };

    dLayer({
      event: 'interaction',
      site_section: 'form',
      action: 'send',
      label: label[type],
    });
  },

  /**
   * @description отправка Id пользователя
   * @param {string} userId - Id пользователя (id майнбокса)
   */
  sendUserId(userId) {
    ymAnalytic(yandexMetricsId, 'userParams', {
      UserID: userId,
    });
  },
};

export default {
  commonAnalytic,
};
